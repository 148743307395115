import WebServiceRequest from "./WebServiceRequest";

class WalletCreateRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/Create");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}
class WalletUpdateRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/Update");
  }
  setParams(data) {
    super.setRequestParamDataObj(data);
  }
}
class WalletGetMyWalletsRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/GetMyWallets");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
class WalletGetRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/Get");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
class WalletGetAllRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/GetAll");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
class WalletDeleteRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/Delete");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
class WalletGetActiveWalletsRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/GetActiveWallets");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
class WalletGetByUserIdRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/GetByUserId");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
class WalletUpdateWalletRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/UpdateWallet");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
class WalletCheckCreditRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/CheckCredit");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
class WalletGetCreditRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/GetCredit");
  }
}
class WalletTransferToBlockchainRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/TransferToBlockchain");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
class WalletCreateTRC20AddressRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/CreateTRC20Address");
  }
}
class WalletGetWalletDataRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/GetBlockchainCredit");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
class WalletUpdateWalletsRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/UpdateWallets");
  }
}

class WalletGetTotalWalletsRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/GetTotalWallets");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}

class WalletExternalTransferByAdminRequest extends WebServiceRequest {
  constructor(context) {
    super(context);
    super.setTag("Wallet/ExternalTransferByAdmin");
  }
  setParams(data) {
    super.setRequestParam(data);
  }
}
export {
  WalletCreateRequest,
  WalletUpdateRequest,
  WalletGetMyWalletsRequest,
  WalletGetAllRequest,
  WalletGetActiveWalletsRequest,
  WalletDeleteRequest,
  WalletGetRequest,
  WalletGetByUserIdRequest,
  WalletUpdateWalletRequest,
  WalletCheckCreditRequest,
  WalletGetCreditRequest,
  WalletTransferToBlockchainRequest,
  WalletCreateTRC20AddressRequest,
  WalletGetWalletDataRequest,
  WalletUpdateWalletsRequest,
  WalletGetTotalWalletsRequest,
  WalletExternalTransferByAdminRequest,
};
